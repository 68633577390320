import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./Pages/Login"
import Registration from "./Pages/Registration";
import Dashboard from "./Pages/Dashboard";
import DashboardAdmin from "./Pages/DashboardAdmin";
import ExpenseForm from "./Pages/ExpenseForm";
import AdminUpdateMultiVoucher from "./Pages/AdminUpdateMultiVoucher";
import DashboardAccount from "./Pages/DashboardAccount";
import ExpenseRequestAdmin from "./Pages/ExpenseRequestAdmin";
import AdminUpdateTrevelVoucherDetails from "./Pages/AdminUpdateTrevelVoucherDetails";
import EditTravelVoucher from "./Pages/EditTravelVoucher";
import UserVoucherFill from "./Pages/UserVoucherFill";
import AdminDraftVoucherfinal from "./Pages/AdminDraftVoucherfinal";
import AdminVoucerDetailsFill from "./Pages/AdminVoucerDetailsFill";
import TravelExpenseRequestAccountant from "./Pages/TravelExpenseRequestAccountant";
import EditEvent from "./Pages/EditEvent";
import AccountantEditMultiVoucherFill from "./Pages/AccountantEditMultiVoucherFill";
import Test from "./Pages/Test";
import ExpenseRequestAccountant from "./Pages/ExpenseRequestAccountant";
import TravelVoucherFill from "./Pages/TravelVoucherFill";
import TravelUserVoucherDetails from "./Pages/TravelUserVoucherDetails";
import AdminEditVoucher from "./Pages/AdminEditVoucher";
import AcceptedVoucher from "./Pages/AcceptedVoucher";
import PendingVoucher from "./Pages/PendingVoucher";
import RejectedVoucher from "./Pages/RejectedVoucher";
import AdminTravelVoucherFill from "./Pages/AdminTravelVoucherFill";
import TravelExpenseRequest from "./Pages/TravelExpenseRequest";
import AnalyticsVoucher from "./Pages/AnalyticsVoucher";
import TravelExpUserPage from "./Pages/TravelExpUserPage";
import TravelExpUserPageaccountant from "./Pages/TravelExpUserPageaccountant";
import DraftVoucerDetailsFill from "./Pages/DraftVoucerDetailsFill";
import AccVoucherFill from "./Pages/AccVoucherFill";
import AdminUpdateVoucherDetails from "./Pages/AdminUpdateVoucherDetails";
import ApprovedAdminVoucherdetails from "./Pages/ApprovedAdminVoucherdetails";
import AccMultiVoucherFill from "./Pages/AccMultiVoucherFill";
import EditVoucher from "./Pages/EditVoucher";
import AdminLocalConveyanceVoucherFill from "./Pages/AdminLocalConveyanceVoucherFill ";
import UserLocalConveyanceVoucher from "./Pages/UserLocalConveyanceVoucher";
import UserVoucherMulti from "./Pages/UserVoucherMulti";
import AdminMultiVoucherFill from "./Pages/AdminMultiVoucherFill";
import EditMultiVoucher from "./Pages/EditMultiVoucher";
import AllAnalytics from "./Pages/AllAnalytics";
import ProfilePage from "./Pages/ProfilePage";
import VoucherDetails from "./Pages/VoucherDetails";
import AdminExpenseFillByAcc from "./Pages/AdminExpenseFillByAcc";
import UpdateProfileUser from "./Pages/UpdateProfileUser";
import AdminVoucherDetailsAll from "./Pages/AdminVoucherDetailsAll";
import UserDetails from "./Pages/UserDetails";
import AdminMultiVoucherByAcc from "./Pages/AdminMultiVoucherByAcc";
import EditUserProfile from "./Pages/EditUserProfile";
import AllAppliedAnalytics from "./Pages/AllAppliedAnalytics";
import OfficialFillbyAccountant from "./Pages/OfficialFillByAccountant";
import AllAppliedAnalyticsyqm from "./Pages/AllAppliedAnalyticsyqm";
import EditVoucherAccountant from "./Pages/EditVoucherAccountant";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventCreate from "./component/EventCreate";
import AdminEvent from "./component/AdminEvent";
import TravelEventCreate from "./component/TravelEventCreate";

const App = () => {
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <>
      <ToastContainer />
     

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Test" element={<Test />} />
          <Route path="/EventCreate" element={<EventCreate />} />
          <Route path="/EditVoucher" element={<EditVoucher />} />
          <Route path="/EditEvent" element={<EditEvent />} />
          <Route path="/UserVoucherMulti" element={<UserVoucherMulti />} />
          <Route path="/VoucherDetails" element={<VoucherDetails />} />
          <Route path="/UpdateProfileUser" element={<UpdateProfileUser />} />
          <Route path="/EditTravelVoucher" element={<EditTravelVoucher />} />
          <Route path="/ProfilePage" element={<ProfilePage />} />
          <Route path="/EditMultiVoucher" element={<EditMultiVoucher />} />
          <Route path="/UserLocalConveyanceVoucher" element={<UserLocalConveyanceVoucher />} />







          {isLoggedIn ? (
            <>
              {user?.role === 'User' && (
                <>
                  <Route path="/Dashboard" element={<Dashboard />} />
                  <Route path="/UserVoucherFill" element={<UserVoucherFill />} />
                  <Route path="/TravelVoucherFill" element={<TravelVoucherFill />} />
                  <Route path="/AnalyticsVoucher" element={<AnalyticsVoucher />} />
                  <Route path="/AcceptedVoucher" element={<AcceptedVoucher />} />
                  <Route path="/PendingVoucher" element={<PendingVoucher />} />
                  <Route path="/RejectedVoucher" element={<RejectedVoucher />} />



                </>
              )}

              {user?.role === 'Admin' && (
                <>
                  <Route path="/DashboardAdmin" element={<DashboardAdmin />} />
                  <Route path="/ExpenseRequestAdmin" element={<ExpenseRequestAdmin />} />
                  <Route path="/AdminVoucerDetailsFill" element={<AdminVoucerDetailsFill />} />
                  <Route path="/AdminTravelVoucherFill" element={<AdminTravelVoucherFill />} />
                  <Route path="/TravelEventCreate" element={<TravelEventCreate />} />
                  <Route path="/TravelExpenseRequest" element={<TravelExpenseRequest />} />
                  <Route path="/TravelUserVoucherDetails" element={<TravelUserVoucherDetails />} />
                  <Route path="/Registration" element={<Registration />} />
                  <Route path="/TravelExpUserPage" element={<TravelExpUserPage />} />
                  <Route path="/AllAppliedAnalytics" element={<AllAppliedAnalytics />} />
                  <Route path="/EditUserProfile" element={<EditUserProfile />} />
                  <Route path="/AdminEditVoucher" element={<AdminEditVoucher />} />
                  <Route path="/AdminLocalConveyanceVoucherFill" element={<AdminLocalConveyanceVoucherFill />} />
                  <Route path="/AllAppliedAnalyticsyqm" element={<AllAppliedAnalyticsyqm />} />
                  <Route path="/ApprovedAdminVoucherdetails" element={<ApprovedAdminVoucherdetails />} />
                  <Route path="/AdminMultiVoucherFill" element={<AdminMultiVoucherFill />} />
                  <Route path="/AdminVoucherDetailsAll" element={<AdminVoucherDetailsAll />} />
                  <Route path="/AdminUpdateVoucherDetails" element={<AdminUpdateVoucherDetails />} />
                  <Route path="/AdminUpdateTrevelVoucherDetails" element={<AdminUpdateTrevelVoucherDetails />} />
                  
                  <Route path="/AllAnalytics" element={<AllAnalytics />} />
                  <Route path="/DraftVoucerDetailsFill" element={<DraftVoucerDetailsFill />} />
                  

                  <Route path="/ExpenseForm" element={<ExpenseForm />} />
                  <Route path="/AdminUpdateMultiVoucher" element={<AdminUpdateMultiVoucher />} />
                  <Route path="/AdminDraftVoucherfinal" element={<AdminDraftVoucherfinal />} />
                  <Route path="/UserDetails" element={<UserDetails />} />

                </>
              )}
              {user?.role === 'Accountant' && (
                <>
                  <Route path="/DashboardAccount" element={<DashboardAccount />} />
                  <Route path="/ExpenseRequestAccountant" element={<ExpenseRequestAccountant />} />
                  <Route path="/UserVoucherFill" element={<UserVoucherFill />} />
                  <Route path="/OfficialFillbyAccountant" element={<OfficialFillbyAccountant />} />
                  <Route path="/TravelVoucherFill" element={<TravelVoucherFill />} />
                  <Route path="/AllAppliedAnalytics" element={<AllAppliedAnalytics />} />
                  <Route path="/AllAppliedAnalyticsyqm" element={<AllAppliedAnalyticsyqm />} />
                  <Route path="/TravelExpenseRequestAccountant" element={<TravelExpenseRequestAccountant />} />
                  <Route path="/Registration" element={<Registration />} />
                  <Route path="/TravelExpUserPageaccountant" element={<TravelExpUserPageaccountant />} />
                  <Route path="/AdminVoucherDetailsAll" element={<AdminVoucherDetailsAll />} />
                  <Route path="/AccVoucherFill" element={<AccVoucherFill />} />
                  <Route path="/AccMultiVoucherFill" element={<AccMultiVoucherFill />} />
                  <Route path="/UserDetails" element={<UserDetails />} />
                  <Route path="/ApprovedAdminVoucherdetails" element={<ApprovedAdminVoucherdetails />} />
                  <Route path="/EditVoucherAccountant" element={<EditVoucherAccountant />} />
                  <Route path="/AdminMultiVoucherByAcc" element={<AdminMultiVoucherByAcc />} />
                  <Route path="/AdminEvent" element={<AdminEvent />} />
                  <Route path="/AdminExpenseFillByAcc" element={<AdminExpenseFillByAcc />} />
                  <Route path="/AccountantEditMultiVoucherFill" element={<AccountantEditMultiVoucherFill />} />
                  <Route path="/AllAnalytics" element={<AllAnalytics />} />



                </>
              )}
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        </Routes>

     
    </>
  );
};

export default App;