import React, { useState, useEffect } from 'react';
import { TERipple, TEModal, TEModalDialog, TEModalContent, TEModalHeader, TEModalBody, TEModalFooter } from "tw-elements-react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import ShowMoreText from '../utilities/ShowMoreText';
import formatDate from '../utilities/formatDate';
import getFileNameAndType from '../utilities/getFileNameAndType';
import pdfIcon from '../Images/PDF_file_icon.svg.png';
import imgIcon from '../Images/7156838.png';
import { toast } from "react-toastify";
import { getCurrentDate } from "../utilities/currentDateTime";
import formatExpenseDateInData from "../utilities/formatExpenseDateInData";



const VoucherPopup1 = ({ data, openPopup }) => {
    const [showVoucher, setShowVoucher] = useState(openPopup);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [company, setCompany] = useState([]);
    const [bank, setBank] = useState([]);
    const [remark, setRemark] = useState('');
    const [userVoucherData, setUserVoucherData] = useState([]);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        PaymentType: '',
        CompanyId: '',
        LocationId: '',
        BankId: '',
        paymentDate: ''
    });


    useEffect(() => {
        setShowVoucher(openPopup);
    }, [openPopup]);

    const handleClosePopup = () => {
        setShowVoucher(false); // Close the popup
    };

    const fetchUserVoucherData = async () => {
     
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_payment_details_for_accountant_by_voucher_id/${voucherId}`;

        try {
            const response = await axios.get(url);
            const data = response.data;
            setUserVoucherData(data);
  
            console.log('User Voucher Data payment', data);
        } catch (err) {
            console.log('User Expense Data Error', err.response.data);
        }
    };

    const fetchCompany = async () => {
        try {
          const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
          setCompany(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };
    const handleAcceptVoucher = async (requestVoucherId) => {
        try {
            const data = { voucherId: requestVoucherId, remark: remark, adminId: userData.userId };
            const response = await axios.put('https://expenseapi.sarahtech.com/api/Admin/approved_by_admin', data);
            console.log('Response Handle Accept Expense Vocher:', response.data);
            toast.success('Voucher Accepted successfully!');
            setRemark('');
            handleClosePopup(); 
        } catch (error) {
            console.error('Error:', error);
            setRemark('');
        }
    };

    const handleRejectVoucher = async (requestVoucherId) => {
        if (!remark.trim()) {
            setError('Remark is required for rejection.');
        } else {
            setError('');
            try {
                const data = { voucherId: requestVoucherId, remark: remark, adminId: userData.userId };
                const response = await axios.put('https://expenseapi.sarahtech.com/api/Admin/reject_by_admin', data);
                console.log('Response:', response.data);
                toast.success('Voucher rejected successfully!');
                setRemark('');
                handleClosePopup();  // Close the popup on successful rejection
            } catch (error) {
                console.error('Error:', error);
                toast.error('Failed to reject the voucher.');
                setRemark('');
            }
        }
    };
    
    
    
    const handleApproveVoucher = async (requestVoucherId, expenseAmount, userId, eventId) => {
        debugger
        if (remark.trim() === '') {
            toast.error('Remark is required to approve the voucher.');
            return;
        }
    
        try {
            const data = { 
                VoucherId: requestVoucherId, 
                Remark: remark, 
                Amount: expenseAmount, 
                userId: userId, 
                eventId: eventId,
                
                // If formData.CompanyId exists, use it; otherwise, fall back to userVoucherData.companyId
                companyId: formData.CompanyId || userVoucherData.companyId || '0',
                LocationId: formData.LocationId || userVoucherData.LocationId || '0',
                bankId: formData.BankId || userVoucherData.bankId || '0',
                paymentType: formData.PaymentType || userVoucherData.paymentType || '0',
                paymentDate: formData.paymentDate ?? null,  // Assuming you want null for PaymentDate if not available
                accountantId: userData.userId
            };
            
    
            console.log('Sending Data By Accountant', data);
            const response = await axios.put('https://expenseapi.sarahtech.com/api/Accounts/approved_by_accountant', data);
            console.log('Response Handle Accept Expense Voucher:', response.data);
            toast.success('Voucher approved successfully!');
            setRemark('');
            handleClosePopup(); 
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to approve the voucher.');
            setRemark('');
        }
    };
    
    
    


    const handleChange = (e) => {
        console.log('handle change', e.target.value, e.target.name);
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    
     

    
          if (name === 'CompanyId') {
    
         
    
              const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_bank_details_by_company_id/${value}`;
          
              axios.get(url)
                .then((response) => {
                  console.log("bank: ", response.data);
                  setBank(response.data);
                  // Uncomment the line below if you want to show a success message to the user
                  // toast.success("Expense subtypes loaded successfully.");
                })
                .catch((err) => {
                  console.error('Response Error: ', err.response ? err.response.data : err.message);
                  toast.error("Failed to load expense subtypes.");
                });
          }
    
    
          if (name === 'BankId') {
            debugger
    
         
    
            const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_payment_details_by_bank_id/${value}`;
        
            axios.get(url)
              .then((response) => {
                console.log("Pay Type: ", response.data);
                setPaymentTypes(response.data);
                // Uncomment the line below if you want to show a success message to the user
                // toast.success("Expense subtypes loaded successfully.");
              })
              .catch((err) => {
                console.error('Response Error: ', err.response ? err.response.data : err.message);
                toast.error("Failed to load expense subtypes.");
              });
        }
      };


      useEffect(() => {
         fetchCompany();
         fetchUserVoucherData();
      }, []);

    const userData = useSelector(state => state.auth.user);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const { voucherId, expenseType, expenseSubType, expenseDate, expenseAmount, description, attachmentPath, fromSource, toSource, distanceTravelled, fromDate, toDate, status, userId, eventId, expenseName } = data || {};
    const { fileName, fileType } = getFileNameAndType(attachmentPath);
    const fileUrl = `https://expenseapi.sarahtech.com/api/Files/view/${fileName}.${fileType}`;
console.log('object1212', expenseDate)
    return (
        <TEModal show={showVoucher} setShow={setShowVoucher}>
            <TEModalDialog size="lg">
                <TEModalContent>
                    <TEModalHeader>
                        <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                            Voucher Details
                        </h5>
                        <button
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={handleClosePopup}
                            aria-label="Close"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </TEModalHeader>

                    <TEModalBody>
                        <div className="border border-black p-3">
                            <div className="flex px-8">
                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                    <p className="font-bold">Voucher Id: <span className='font-normal'>{voucherId}</span></p>
                                </div>
                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1"></div>
                            </div>

                            <div className="flex">
                                <div className="flex-grow p-4" style={{ flexGrow: 3 }}>
                                    <div className="w-full px-4 py-2 flex items-center">
                                        <div className="w-full">
                                            <div className="flex w-full sm:w-1/2 lg:w-1/2 px-4 py-1">
                                                <p className="font-bold">Expense Type:</p>
                                                <span className='font-normal'>{expenseType}</span>
                                            </div>
                                            <div className="w-full px-4 py-1">
                                                <p className="font-bold">Expense Sub Type: <span className='font-normal'>{expenseSubType}</span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Amount: <span className='font-normal'>{expenseAmount} ₹</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-3/4 px-4 py-2 flex items-center">
                                        <div className="w-full">
                                            <div className="w-full px-4 py-1">
                                                <p className="font-bold">Expense Date: <span className='font-normal'>{formatDate(expenseDate)}</span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Status: <span className={`font-normal capitalize px-3 py-1 inline-block rounded-full cursor-pointer text-white text-center ${status === "Accepted" && "bg-yellow-500"} ${status === "Approved" && "bg-green-500"} ${status === "Pending" && "bg-orange-500"} ${status === "Rejected" && "bg-red-600"}`}> {status} </span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-3/4 px-4 py-2 flex items-center">
                                        <div className="flex w-full px-4 py-1">
                                           
                                            <ShowMoreText text={description} maxWords={10} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow p-4 flex items-center">
                                    {fileType === 'pdf' ? (
                                        <img src={pdfIcon} alt="PDF Icon" className="mr-4 w-12 h-12" />
                                    ) : (
                                        <img src={imgIcon} alt="Image" className="mr-4 w-12 h-12" />
                                    )}
                                    <span className='text-blue'><a href={fileUrl} target="_blank" rel="noopener noreferrer">{`View.${fileType}`}</a></span>
                                </div>
                            </div>

                            {expenseSubType === 'FUEL EXPENSE' && (
                                <div className="flex p-4">
                                    <div className="flex-grow lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                    <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">From : <span className="font-normal">{fromSource} </span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">To : <span className="font-normal">{toSource}</span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Distance : <span className="font-normal">{distanceTravelled}&nbsp;km</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


{expenseSubType === 'MISCELLANEOUS EXPENSE' && (
                                <div className="flex p-4">
                                    <div className="flex-grow lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Expense Name: <span className="font-normal">{expenseName}</span></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            )}

                            {expenseSubType === 'TRAVEL EXPENSE' && (
                                <div className="flex p-4">
                                    <div className="flex-grow lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">From Date: <span className="font-normal">{formatDate(fromDate)}</span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">To Date: <span className="font-normal">{formatDate(toDate)}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

{isLoggedIn && userData.role === 'Admin' && status === 'Pending' && (
                <div className="flex items-center space-x-4 p-4">
                    <textarea
                        className="flex-1 border border-gray-300 rounded p-2"
                        rows="2"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        placeholder="Enter your text here..."
                    ></textarea>
                    <button
                        className="bg-green-500 text-white px-4 py-2 rounded"
                        onClick={() => { handleAcceptVoucher(voucherId);  }}
                    >
                        Accept
                    </button>
                    <button
                        className="bg-orange-500 text-white px-4 py-2 rounded"
                        onClick={() => { handleRejectVoucher(voucherId);  }}
                    >
                        Reject
                    </button>
                </div>
            )}
            {error && <div className="text-red-500 p-4">{error}</div>}
                                
                                {isLoggedIn && userData.role === 'Accountant' && status === 'Accepted' && (
        <>
          <div className="md:flex md:items-center 3xl:mb-6 mb-3">
    <div className="md:w-1/3">
        <label
            htmlFor="Company"
            className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
        >
            Company
        </label>
    </div>
    <div className="md:w-1/3">
        <select
            id="Company"
            name="CompanyId"
            value={formData.CompanyId || userVoucherData.companyId}  
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
        >
            {/* Show companyName but save companyId */}
            <option value={userVoucherData.companyId}>
                {userVoucherData.companyName}
            </option>
            {company.map((item) => (
                <option key={item.companyId} value={item.companyId}>
                    {item.companyName}
                </option>
            ))}
        </select>
    </div>
</div>


            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                    <label
                        htmlFor="Location"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                    >
                        Location
                    </label>
                </div>
                <div className="md:w-1/3">
                    <select
                        id="Location"
                        name="LocationId"
                        value={formData.LocationId || userVoucherData.LocationId}  
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    >
                         <option value={userVoucherData.LocationId}>
                {userVoucherData.LocationName}
            </option>
                        {company.map((item) => (
                            <option key={item.LocationId} value={item.LocationId}>
                                {item.LocationName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                    <label
                        htmlFor="Bank"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                    >
                        Bank
                    </label>
                </div>
                <div className="md:w-1/3">
                    <select
                        id="Bank"
                        name="BankId"
                        value={formData.BankId || userVoucherData.BankId}  
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    >
                         <option value={userVoucherData.bankId}>
                {userVoucherData.bankName}
            </option>
                        {bank.map((item) => (
                            <option key={item.bankId} value={item.bankId}>
                                {item.bankName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                    <label
                        htmlFor="PaymentTypes"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                    >
                        Payment Type
                    </label>
                </div>
                <div className="md:w-1/3">
                    <select
                        id="PaymentTypes"
                        name="PaymentType"
                        value={formData.PaymentType || userVoucherData.PaymentType}  

                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    >
                         <option value={userVoucherData.paymentType}>
                {userVoucherData.paymentType}
            </option>
                        {paymentTypes.map((item) => (
                            <option key={item.paymentMasterId} value={item.paymentType}>
                                {item.paymentType}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="md:flex md:items-center mb-3 2xl:mb-6">
  <div className="md:w-1/3">
    <label
      htmlFor="Payment Date"
      className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
      Payment Date:
    </label>
  </div>

  <div className="md:w-1/3">
    <input
      type="date"
      placeholder="Payment Date"
      name="paymentDate"
      value={formData.paymentDate}
      onChange={handleChange}
      required
      max={getCurrentDate()}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
    />
  </div>
</div>

            <div className="w-2/3 flex items-center space-x-4 p-4">
                <textarea 
                    className="flex-1 border border-gray-300 rounded p-2" 
                    rows="2" 
                    value={remark} 
                    onChange={(e) => setRemark(e.target.value)} 
                    placeholder="Enter your text here..."
                    required
                  
                ></textarea>                
                <button 
                    className="bg-green-500 text-white px-4 py-2 rounded" 
                    onClick={() => {
                        handleApproveVoucher(voucherId, expenseAmount, userId, eventId);
                      
                    }}
                >
                    Approved
                </button>
            </div>
        </>
    )}

                                
                        </div>
                    </TEModalBody>

                    <TEModalFooter>

                  
                        <TERipple rippleColor="light">
                            <button
                                type="button"
                                className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                onClick={() => handleClosePopup()}
                            >
                                Close
                            </button>
                        </TERipple>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    );
};

export default VoucherPopup1;
